import customerCentric from '../../../assets/customer-centric.png';
import honesty from '../../../assets/honesty.png';
import simplicity from '../../../assets/simplicity.png';

const OurValuesCards = [
  {
    image: customerCentric,
    title: 'Customer centric',
    text: 'We endeavor to  offer innovative products clubbed with superior customer service to build a smooth and effortless investment experience for all',
  },
  {
    image: honesty,
    title: 'Honesty & Integrity',
    text: `We uphold the highest standards of integrity and honesty in whatever we do.If at any point you feel you've we have not lived upto your expectations, you can write to our founder on `,
    link: `<a href='mailto:saumya@tarrakki.com'>saumya@tarrakki.com</a>`,
  },
  {
    image: simplicity,
    title: 'Simplicity',
    text: 'We strive to offer unparalleled simplicity in all the products or services we offer',
  },
];

export default OurValuesCards;
