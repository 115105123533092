import { SHOW_HEDER_OPTIONS, NAV_SELECTION_CHANGE } from '../constant';

export const updateHeaderOption = (payload) => ({
  type: SHOW_HEDER_OPTIONS,
  payload,
});
export const updateSelectedNav = (payload) => ({
  type: NAV_SELECTION_CHANGE,
  payload,
});
