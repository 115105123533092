const SidebarData = [
  {
    title: 'Homepage',
    path: '/',
    cName: 'nav-text',
  },
  {
    title: 'About Us',
    path: '/AboutUs',
    cName: 'nav-text',
  },
  {
    title: 'Why elevo',
    path: '/whyelevo',
    cName: 'nav-text',
  },
  {
    title: 'Our Core Values',
    path: '/ourvalues',
    cName: 'nav-text',
  },
  {
    title: 'What Our Clients Say',
    path: '/userreviews',
    cName: 'nav-text',
  },
  {
    title: 'Privacy Policy',
    path: '/privacy',
    cName: 'nav-text',
  },
  {
    title: 'Terms & Conditions',
    path: '/tos',
    cName: 'nav-text',
  },
];

export default SidebarData;
