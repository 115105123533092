import React from 'react';
import { Route, Switch } from 'react-router-dom';
import HomePage from '../components/home-page/HomePage';
import AboutUs from '../components/about-us/AboutUs';
import WhyElevo from '../components/home-page/home-sub-pages/OurMotives';
import OurValues from '../components/about-us/aboutus-pages/OurValues';
import UsersReviews from '../components/home-page/home-sub-pages/MobileUserReview';
import TermsAndConditions from '../utility/Terms&Conditions';
import Privacypolicy from '../utility/PrivacyPolicy';

const Routers = () => {
  return (
    <Switch>
      <Route exact path='/' component={HomePage} />
      <Route exact path='/aboutus' component={AboutUs} />
      <Route exact path='/whyelevo' component={WhyElevo} />
      <Route exact path='/ourvalues' component={OurValues} />
      <Route exact path='/userreviews' component={UsersReviews} />
      <Route path='/tos' component={TermsAndConditions} />
      <Route path='/privacy' component={Privacypolicy} />
    </Switch>
  );
};
export default Routers;
