import CryptoJS from 'crypto-js';

const decryptData = (passData) => {
  const key = CryptoJS.enc.Utf8.parse(atob(process.env.REACT_APP_DECRYPT_KEY));
  const iv = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_DECRYPT_NO);
  const bytes = CryptoJS.AES.decrypt(passData, key, { iv: iv });
  let originalB1 = bytes.toString(CryptoJS.enc.Utf8);
  if (originalB1.includes('}*#$*')) {
    originalB1 = originalB1.slice(0, -4);
  }
  return JSON.parse(originalB1);
};

const encryptData = (passData) => {
  let input;
  if (typeof passData === 'string') {
    input = passData;
  } else {
    input = JSON.stringify(passData);
  }
  const key = CryptoJS.enc.Utf8.parse(atob(process.env.REACT_APP_DECRYPT_KEY));
  const iv = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_DECRYPT_NO);
  const bytes = CryptoJS.AES.encrypt(input, key, { iv: iv });
  let originalB1 = bytes;
  return originalB1;
};

export { encryptData, decryptData };
