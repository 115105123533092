import React, { useState } from 'react';
import { Button, Navbar, Container } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import tarrakkiLogo from '../../assets//elevo/ELEVO.png';
import { useDispatch, useSelector } from 'react-redux';
import { updateSelectedNav } from '../../redux/actions/headerAction';
import { HashLink as Link } from 'react-router-hash-link';
import SidebarData from './SidebarData';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import { IoIosArrowForward } from 'react-icons/io';
import './Navbar.scss';

const Header = () => {
  const { activeLink } = useSelector((state) => state.headerReducer);
  const dispatch = useDispatch();
  const [sidebar, setSidebar] = useState(false);
  const [aboutUrl] = useState('');

  const showSidebar = () => setSidebar(!sidebar);
  const updateActiveLink = (link) => {
    dispatch(updateSelectedNav(link));
  };
  const getTitle = (value) => {
    let name = value.replace(' ', '').toLowerCase();
    return name;
  };

  return (
    <>
      <Navbar
        collapseOnSelect
        expand='lg'
        variant='light'
        className='navbar-light bg-white mt-2 mb-4 mx-0'
      >
        <Container fluid>
          <div className='navbar'>
            <Link to='#' className='menu-bars'>
              <FaIcons.FaBars onClick={showSidebar} />
            </Link>
          </div>
          <nav className={sidebar ? 'nav-menu active' : 'nav-menu'}>
            <ul className='nav-menu-items' onClick={showSidebar}>
              <Link className='navbar-brand mb-4 mt-4' to='/'>
                <img
                  src={tarrakkiLogo}
                  className='logo'
                  alt='Tarrakki Towards Prosperity'
                />
              </Link>
              <Link to='#' className='menu-bars mr-4 mt-4 float-right'>
                <AiIcons.AiOutlineClose className='mr-2' />
              </Link>
              {SidebarData.map((item, index) => {
                return (
                  <>
                    {item.title === 'About Us' ? (
                      aboutUrl && (
                        <>
                          <li
                            key={index}
                            className={`${item.cName} nav-item mr-4 ${
                              activeLink === getTitle(item.title) && 'active'
                            }`}
                            onClick={() =>
                              updateActiveLink(getTitle(item.title))
                            }
                          >
                            <a
                              href={aboutUrl}
                              target='_blank'
                              rel='noreferrer'
                              to={item.path}
                              className='nav-link mt-3'
                            >
                              <span>{item.title}</span>
                              <span className='float-right mr-20'>
                                <IoIosArrowForward />
                              </span>
                            </a>
                            f
                          </li>

                          <hr className='m-0 p-0 ml-3 w-75'></hr>
                        </>
                      )
                    ) : (
                      <>
                        <li
                          key={index}
                          className={`${item.cName} nav-item mr-4 ${
                            activeLink === getTitle(item.title) && 'active'
                          }`}
                          onClick={() => updateActiveLink(getTitle(item.title))}
                        >
                          <Link to={item.path} className='nav-link mt-3'>
                            <span>{item.title}</span>
                            <span className='float-right mr-20'>
                              <IoIosArrowForward />
                            </span>
                          </Link>
                        </li>

                        <hr className='m-0 p-0 ml-3 w-75'></hr>
                      </>
                    )}
                  </>
                );
              })}
              <li
                className={`nav-text nav-item mr-4 ${
                  activeLink === 'login' && 'active'
                }`}
                onClick={() => updateActiveLink('login')}
              >
                <a href={process.env.REACT_APP_AUTH_URL}>
                  <span>Log in</span>
                  <span className='float-right mr-20'>
                    <IoIosArrowForward />
                  </span>
                </a>
              </li>
            </ul>
          </nav>
          <Link className='navbar-brand' to='/'>
            <img
              src={tarrakkiLogo}
              className='logo'
              alt='Tarrakki Towards Prosperity'
            />
          </Link>
          <a href={process.env.REACT_APP_AUTH_URL}>
            <Button className='br-light'>Login</Button>
          </a>
        </Container>
      </Navbar>
    </>
  );
};
Header.propTypes = {
  history: PropTypes.object,
};
export default withRouter(Header);
