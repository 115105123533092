import React from 'react';
import Footer from './Footer';
import MobileFooter from './MobileFooter';
import Introduction from './home-sub-pages/Introduction';
import OurMotives from './home-sub-pages/OurMotives';
import TaxStatus from './home-sub-pages/TaxStatus';
import UsersReviews from './home-sub-pages/UsersReviews';
import MobileUserReview from './home-sub-pages/MobileUserReview';
import MobileTaxStatus from './home-sub-pages/MobileTaxStatus';
import { BrowserView, MobileView } from 'react-device-detect';

const HomePage = () => {
  return (
    <section id='Homepage'>
      <Introduction />
      <OurMotives />
      <BrowserView>
        <TaxStatus />
        <UsersReviews />
        <Footer />
      </BrowserView>
      <MobileView>
        <MobileTaxStatus />
        <MobileUserReview />
        <MobileFooter />
      </MobileView>
    </section>
  );
};
export default HomePage;
