import React from 'react';
import bse_icon from '../../assets/bse.png';
import amfi_icon from '../../assets/amfi.png';
import sebi_icon from '../../assets/sebi.png';
import { HashLink as Link } from 'react-router-hash-link';
import { Container, Row, Col } from 'react-bootstrap';
import tarrakkiLogo from '../../assets//elevo/ELEVO.png';
import {
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaLinkedinIn,
  FaYoutube,
} from 'react-icons/fa';

const Footer = () => {
  return (
    <section className='text-black footer text-lg-left mt-6 '>
      <Container>
        <Row>
          <Col lg='5' md='12' className='mb-4 mb-md-0'>
            <img
              src={tarrakkiLogo}
              alt='Tarrakki Towards Prosperity'
              className='logo'
            />
          </Col>
          <Col lg='2' md='6' className='mb-4 mb-md-0'>
            <p className='footerpara footerpara-heading'>Company</p>
            <p className='footerpara'>
              <Link to='/AboutUs' smooth className='hyperlink'>
                About Us
              </Link>
            </p>
            <p className='footerpara'>
              <Link to={'/whyelevo'} smooth className='hyperlink'>
                Why Elevo
              </Link>
            </p>
            <p className='footerpara'>
              <a href='https://www.tarrakki.com/news' className='hyperlink'>
                Press
              </a>
            </p>
          </Col>
          <Col lg='2' md='6' className='mb-4 mb-md-0 text-lg-left'>
            <p className='footerpara footerpara-heading'>Support</p>
            <p className='footerpara'>
              <a href='mailto:support@elevo.money.com' className='hyperlink'>
                support@elevo.money
              </a>
            </p>
          </Col>
          <Col lg='3' md='6' className='mb-4 mb-md-0'>
            <p className='footerpara'>801 Sapphire Complex,</p>
            <p className='footerpara'>Opposite Union Bank of India,</p>
            <p className='footerpara'>Chimanlal Girdharlal Rd,</p>
            <p className='footerpara'>Near Cargo Motors,</p>
            <p className='footerpara'>Ahmedabad, Gujarat 380006</p>
          </Col>
        </Row>
      </Container>
      <Container className='mb-5 mt-4'>
        <Row>
          <Col lg='3' md='6' className='mb-4 mb-md-0'>
            <img src={bse_icon} alt='icon-1' className='w-50 ' id='img-fluid' />
          </Col>
          <Col lg='2' md='6' className='mb-4 mb-md-0'>
            <img src={amfi_icon} alt='icon-2' className='w-50' id='img-fluid' />
          </Col>
          <Col lg='4' md='6' className='mb-4 mb-md-0'>
            <img src={sebi_icon} alt='icon-3' className='w-50' id='img-fluid' />
          </Col>
          <Col lg='3' md='6' className='mb-4 mb-md-0'>
            <span className='icons'>
              <a
                href='https://www.facebook.com/elevo.money/'
                target='_blank'
                rel='noreferrer'
              >
                {' '}
                <FaFacebookF size={20} color='black' />
              </a>
            </span>
            <span className='icons'>
              <a
                href='https://instagram.com/elevo.money?utm_medium=copy_link'
                target='_blank'
                rel='noreferrer'
              >
                <FaInstagram size={20} color='black' />
              </a>
            </span>
            <span className='icons'>
              <a
                href='https://twitter.com/elevo_?s=21'
                target='_blank'
                rel='noreferrer'
              >
                <FaTwitter size={20} color='black' />
              </a>
            </span>
            <span className='icons'>
              <a
                href='https://www.linkedin.com/company/tarrakki/'
                target='_blank'
                rel='noreferrer'
              >
                <FaLinkedinIn size={20} color='black' />
              </a>
            </span>
            <span className='icons'>
              <a
                href='https://m.youtube.com/channel/UC4vQy-jvqZvrSgngpoFG-_g'
                target='_blank'
                rel='noreferrer'
              >
                <FaYoutube size={20} color='black' />
              </a>
            </span>
          </Col>
        </Row>
      </Container>
      <hr />
      <div className='copyright container mt-4 '>
        <span>
          <Link smooth to='/tos#tos' className='hyperlink'>
            Terms Of Service
          </Link>{' '}
          &
          <Link smooth to='/privacy#privacy' className='hyperlink'>
            {' '}
            Privacy Policy
          </Link>
        </span>
        <span style={{ float: 'right', marginBottom: '1.5rem' }}>
          {' '}
          &copy; Plutonomic Savtech Pvt Ltd. [CIN:U72900GJ2018PTC101542] All
          Rights Reserved
        </span>
      </div>
    </section>
  );
};

export default Footer;
