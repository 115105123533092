import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import './App.scss';
import Routers from './routers/Routers';
import Header from './components/header/Header';
import HeaderMobile from './components/header/HeaderMobile';
import { BrowserView, MobileView } from 'react-device-detect';

const App = () => {
  return (
    <Router>
      <BrowserView>
        <div className='container-fluid'>
          <Header />
          <Routers />
        </div>
      </BrowserView>
      <MobileView>
        <>
          <HeaderMobile />
          <Routers />
        </>
      </MobileView>
    </Router>
  );
};

export default App;
