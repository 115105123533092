import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import aboutus from '../../assets/group3535.png';
import Footer from '../home-page/Footer';
import MobileFooter from '../home-page/MobileFooter';
import OurValues from './aboutus-pages/OurValues';
import { BrowserView, MobileView } from 'react-device-detect';

const AboutUs = () => {
  return (
    <section id='AboutUs'>
      <Container>
        <Row className='mobile-about'>
          <Col lg='6' md='12'>
            <div className='aboutus-heading mt-6'>
              <h1 className='aboutus-title'>
                Restyling Your Investing Experience
              </h1>
              <p className='aboutus-text'>
                First one-of-a-kind seamless investment platform in India that
                provides businesses, HUFs, NRIs, minors, and individuals an
                opportunity to invest and enjoy good returns.
              </p>
            </div>
          </Col>
          <Col lg='6' md='6'>
            <img src={aboutus} alt='aboutimage' className='aboutus-image' />
          </Col>
        </Row>
        <div className='aboutus-paragraph mt-6 mb-6'>
          <p className='aboutus-text2'>What we do</p>
          <div className='mobile-aboutus'>
            <p>
              We built Elevo with the aim to help Individuals, NRIs, Minors,
              HUFs, LLP, Partnerships, Private Limited Companies, and Trusts to
              invest seamlessly. As the investing process for non-individuals is
              cumbersome and tedious, we at Elevo have leveraged technology to
              build a simple,smooth,jargon free,peperless investment experience
              for all.
            </p>
            <p>
              We are the first digital platform in india to offer more than
              3500+ mutual funds at one place for individuals and
              non-individuals to invest. We will also be adding other asset
              classes like P2P lending, Corporate Deposits, Digital Gold and
              many more in the coming months{' '}
            </p>
            <p>
              Elevo the name represents moving upwards. We aspire to keep
              scaling individuals and non-individuals towards prosperity
            </p>
            <p>
              Elevo is a brand of Tarrakki (Plutonomic Savtech Pvt Ltd) with
              AMFI registration ARN-147985
            </p>
          </div>
        </div>
      </Container>
      <OurValues />
      <BrowserView>
        <Footer />
      </BrowserView>
      <MobileView>
        <MobileFooter />
      </MobileView>
    </section>
  );
};

export default AboutUs;
